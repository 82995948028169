import React from "react";
import FreelanceIvanhoe from "../assets/images/freelance-ivanhoe.jpg";

function DidYouKnow() {
  return (
    <div className="did-you-know">
      <h1>Did You Know?</h1>
      <h3>Freelancing has always been a battle. Literally.</h3>
      <br />

      <img src={FreelanceIvanhoe} />

      <p>
        When freelance first came into English in the early 1800s, it was used
        to refer to a medieval mercenary who would fight for whichever nation or
        person paid them the most.
      </p>
      <p>
        Our earliest written evidence for this use (so far, that is) is in Sir
        Walter Scott's novel, Ivanhoe, where a feudal lord refers to the paid
        army he's assembled:
      </p>
      <div className="float-left">
        <blockquote>
          <p>
            I offered Richard the service of my Free Lances, and he refused
            them—I will lead them to Hull, seize on shipping, and embark for
            Flanders; thanks to the bustling times, a man of action will always
            find employment.
          </p>
        </blockquote>
        <p className="did-you-know__source">
          Source:{" "}
          <a
            href="https://www.merriam-webster.com/wordplay/freelance-origin-meaning"
            target="_blank"
          >
            Merriam-Webster
          </a>
        </p>
      </div>
    </div>
  );
}

export default DidYouKnow;
