import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import FreelancersJobListings from "../../assets/images/FreelancersJobListings.svg";
import WorkForFreelancers from "../../assets/images/WorkForFreelancers.svg";
import JonOpportunitiesForFreelancers from "../../assets/images/JonOpportunitiesForFreelancers.svg";
import RelatedLinks from "../../blocks/RelatedLinks";
import IconList, { IconListItem } from "../../components/IconList";

function HowDoesItWorkContent() {
  const RelatedLinksArray = [
    {
      text: "Why Freelancers Directory?",
      link: "/content/resources/why-freelancers-directory",
    },
    {
      text: "Our Motivation",
      link: "/content/resources/our-motivation",
    },
    // {
    //   text: "F.A.Q.",
    //   link: "/content/resources/frequently-asked-questions",
    // },
  ];

  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancersJobListings} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h1>How Does It Work?</h1>
          <h4>
            Discover how easy it is to find the right talent or opportunity.
          </h4>
          <br />
          <p>
            Curious about how it all comes together? Read on to see how it
            works!
          </p>
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper>
        <IconList className="lg-cols-2 lg-rows-3">
          <IconListItem icon={<h3>1.</h3>} alignTextTop>
            <h4>It’s Free to Sign Up</h4>
            <p>
              Joining our platform costs nothing, giving you access to a wide
              range of opportunities without any upfront fees.
            </p>
          </IconListItem>

          <IconListItem icon={<h3>2.</h3>} alignTextTop>
            <h4>Quick and Easy Registration</h4>
            <p>
              Sign up in just 2-3 minutes by entering your basic details like
              skills and location—simple, fast, and straightforward.
            </p>
          </IconListItem>

          <IconListItem icon={<h3>3.</h3>} alignTextTop>
            <h4>Job Offers Sent Directly to You</h4>
            <p>
              Once your profile is complete, there's no need to keep logging in.
              You'll receive relevant job offers directly in your inbox, saving
              you time and hassle.
            </p>
          </IconListItem>

          <IconListItem icon={<h3>4.</h3>} alignTextTop>
            <h4>Effortless Ad Posting for Employers</h4>
            <p>
              Create a list of freelancers, include your contact details, and
              your ad will go straight to their inbox. Freelancers can then
              reach out to you directly, without the need to log in again.
            </p>
          </IconListItem>

          <IconListItem icon={<h3>5.</h3>} alignTextTop>
            <h4>Specialised Platform for Targeted Freelance Work</h4>
            <p>
              We focus on a specific type of freelance work, not a catch-all
              site for any freelance job. While we may not serve every
              freelancer, we excel at supporting the ones we specialise in.
            </p>
          </IconListItem>

          <IconListItem icon={<h3>6.</h3>} alignTextTop>
            <h4>Your Details Are Always Protected</h4>
            <p>
              Your personal information is never shared with advertisers or
              third parties. Advertisers filter by location, skills, and other
              criteria, but they will never have access to any of the details
              you enter on the website.
            </p>
          </IconListItem>
        </IconList>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper className="sm-pt-1 md-pt-1 lg-pt-2">
        <div>
          <h1>Should You Sign Up? Here's Your Checklist:</h1>
          <ol className="checklist mt-2">
            <li>
              <span>I am a freelancer in the creative industry.</span>
            </li>
            <li>
              <span>
                Sometimes I do other work in industries such as hospitality,
                either regularly or occasionally.
              </span>
            </li>
            <li>
              <span>
                I look for new job opportunities at least once every 3 months.
              </span>
            </li>
            <li>
              <span>
                Sometimes I need to hire freelancers in the creative industry,
                either regularly or occasionally.
              </span>
            </li>
            <li>
              <span>
                Sometimes I need last-minute talent (e.g. need a fashion
                photographer for tomorrow in Milan).
              </span>
            </li>
            <li>
              <span>
                I manually search for job opportunities across various websites
                and find it inefficient.
              </span>
            </li>
            <li>
              <span>
                The nature of my work requires short-term, project-based talent,
                often last-minute or with short notice that could be needed
                anywhere in the world.
              </span>
            </li>
          </ol>

          {/* <div className="thought-bubble">
            <div>
              <h4>
                Hey, this is your inner voice talking. Sorry for interrupting
                your procrastination. Did you just say yes to more than 2 or 3
                of these questions? I know you did. I think it is probably best
                to sign up for this website, you know? Looks like they created
                this platform just for people like you. Just saying.
              </h4>

              <Button
                link="/sign-up"
                label="Oversized Sign-up Button!"
                role="primary"
                className="checklist-sign-up-button"
              />
            </div>
          </div> */}
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper className="home-page cols-2 info-card info-card--primary-light mt-2-f xl-pt-1 sm-flex-col-reverse md-flex-col-reverse">
        <div className="col text-section col-sm-100 col-lg-40 col-xl-60 pl-lg-5">
          <h1>Job Hunting Shouldn’t Feel Like a Job</h1>
          <h4>Finding work shouldn’t take more time than doing the work.</h4>
          <br />
          <p>
            For many freelancers, searching for jobs feels like a job in itself,
            often stretching late into the night. The current methods can be
            inefficient and time-consuming, requiring energy that could be spent
            on real work.
          </p>
          <p>
            It’s time for a smarter approach—one that helps you find relevant
            opportunities without the stress of endless scrolling and long
            hours.
          </p>
        </div>
        <div className="col image-section align-self-start col-sm-100 col-lg-60 col-xl-40">
          <img src={WorkForFreelancers} />
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper className="home-page info-card info-card--reversed cols-2 mt-1-f">
        <div className="col image-section col-sm-100 col-lg-60 col-xl-40">
          <img src={JonOpportunitiesForFreelancers} />
        </div>
        <div className="col text-section col-sm-100 col-lg-40 col-xl-60">
          <h1>Stop chasing jobs—let them come to you</h1>
          <h4>Skip the stressful search and let opportunities find you.</h4>
          <br />
          <p>
            Manually searching for freelance jobs often means sending countless
            emails and making endless phone calls, only to face rejection after
            rejection. This process can take a toll on your mental health and
            leave you feeling discouraged.
          </p>

          <p>
            But imagine having relevant job offers delivered straight to your
            inbox. It shifts the experience from stressful to empowering, giving
            you confidence and allowing you to focus on what really
            matters—doing great work.
          </p>
        </div>
      </WebsiteSectionWrapper>

      <RelatedLinks links={RelatedLinksArray} />
    </ContentLayout>
  );
}

export default HowDoesItWorkContent;
