import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import IconList, { IconListItem } from "../../components/IconList";
import { SignUpForm } from "../../pages/SignUpPage";

function PricingContent() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2 sm-flex-col-reverse md-flex-col-reverse flex-align-start-f">
        <div className="col col-sm-100 col-lg-40 col-xl-40 md-pt-1">
          <div className="form-container form-container--neutral-grey sm-mt-1">
            <h1>Sign-up</h1>
            <SignUpForm />
          </div>
        </div>
        <div className="col col-sm-100 col-lg-60 col-xl-60 pt-1">
          <h1>Free for Now, Affordable Forever!</h1>
          <p>
            For now, everything is <b>completely free</b> as we are still in
            beta! This means you can sign up, post ads, and receive job
            notifications without any cost. However, in the coming months, we
            will gradually introduce some paid options.
          </p>
          <p>
            It’s important to note that signing up and standard usage will
            always be free, but for additional features or higher usage, we will
            offer paid plans. These plans are designed to be affordable and
            flexible:
          </p>
          <ol>
            <li>
              <span>
                <h4>Freelancer Essentials</h4> -- Forever free of charge!
              </span>
            </li>
            <li>
              <span>
                <h4>Freelancer Plus</h4> -- £12 per year (just £1 a month)
              </span>
            </li>
            <li>
              <span>
                <h4>Advanced Toolkit</h4> -- £5 per month
              </span>
            </li>
            <li>
              <span>
                <h4>Post an Advert</h4> -- £2.50 per ad
              </span>
            </li>
          </ol>

          <p>
            The details of each plan are still being finalised. For now, these
            titles are here to give you a glimpse of what’s coming. Full
            descriptions will be added soon.
          </p>
          <p>
            <i>
              Note: All prices are in GBP, however, non-UK residents will be
              charged the equivalent in their local currency.
            </i>
          </p>
        </div>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default PricingContent;
