import React from "react";
import { Link } from "react-router-dom";
import ContentLayout from "../layout/ContentLayout";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import JobListingsForCreativeFreelancers from "../assets/images/JobListingsForCreativeFreelancers.svg";

function AboutUsPage() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40 align-self-start">
          <img src={JobListingsForCreativeFreelancers} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h1>About Us</h1>
          <p>
            We're a small team of passionate creatives based in London, united
            by the excitement of building something new and valuable for
            freelancers. As fellow freelancers ourselves, we understand the
            challenges and joys of navigating project-based work. That’s why
            we’ve created this platform—to make finding the right gigs and
            talent easier for people like us.
          </p>
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper>
        <p>
          We’re working tirelessly to improve and expand the features you see
          today. From refining the user experience to adding tools that will
          help you work better, we’re committed to making this platform your
          go-to resource. This is just the beginning, and we can’t wait to share
          what’s coming next!
        </p>
        <p>
          We'd also love your input! If you have any ideas or spot an issue with
          the website or web app, feel free to reach out through our{" "}
          <Link to="/contact-us">Contact Us</Link> page.
          {/* or a dedicated{" "}
          <Link to="/feedback">Feedback</Link> page we've created just for you. */}
        </p>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default AboutUsPage;
