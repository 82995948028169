// Add HubSpot Forms
export function addHBForm(formId, target) {
  const hsScript = document.querySelector("#hsforms");

  if (hsScript) {
    hsScript.remove();
  }

  const script = document.createElement("script");
  script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
  script.id = "hsforms";
  document.body.appendChild(script);

  script.addEventListener("load", () => {
    if (window.hbspt) {
      const hb = window.hbspt.forms.create({
        portalId: "145567813",
        formId,
        region: "eu1",
        target,
      });

      hb.onReady(() => {
        var waitUntil;
        var loopCounter = 0;

        const removeHBLogo = () => {
          loopCounter++;
          // console.log("counter:", loopCounter);

          if (loopCounter > 10) {
            clearInterval(waitUntil);
          }

          const iframe = document.querySelector(".hs-form-iframe");

          if (!iframe) {
            // console.log("no iframe:", iframe);
            return;
          }

          const innerDoc =
            iframe.contentDocument || iframe.contentWindow.document;

          if (!innerDoc) {
            // console.log("no innerDoc");
            return;
          }

          const hsBrand = innerDoc.getElementsByClassName(
            "hs-form__virality-link"
          );

          if (!hsBrand || !hsBrand[0]) {
            // console.log("no hsBrand");
            return;
          }

          hsBrand[0].style.display = "none";

          clearInterval(waitUntil);
        };

        waitUntil = setInterval(removeHBLogo, 100);
      });
    }
  });
}
