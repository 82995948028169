import React from "react";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import Button from "../components/form/Button";
import FreelanceJobSearch from "../assets/images/FreelanceJobSearch.svg";
import FreelancersCommunity from "../assets/images/FreelancersCommunity.svg";
import FreelancersWorking from "../assets/images/FreelancersWorking.svg";
import FreelancerSmartSearch from "../assets/images/FreelancerSmartSearch.svg";
import IconList, { IconListItem } from "../components/IconList";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <>
      <WebsiteSectionWrapper className="home-page sm-pt-1 pt-2 cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelanceJobSearch} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h4>Tired of Looking for Jobs or Freelance Talent?</h4>
          <h1>We May Have an Answer!</h1>
          <ul className="no-style">
            <li>
              <span className="material-symbols-outlined">chevron_right</span>{" "}
              What if you could receive relevant job offers directly in your
              inbox?
            </li>
            <li>
              <span className="material-symbols-outlined">chevron_right</span>{" "}
              And no need to sign in again—just job offers sent straight to your
              email!
            </li>
            <li>
              <Button
                link="/sign-up"
                label="Sign up"
                className="sm-width-100 md-max-width-15 sm-mt-1"
                role="primary"
              />
            </li>
          </ul>
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper>
        <IconList className="lg-cols-3">
          <IconListItem
            icon={<span className="material-symbols-outlined">how_to_reg</span>}
          >
            <h4>Register Once</h4>
            <span>and for free</span>
          </IconListItem>
          <IconListItem
            icon={
              <span className="material-symbols-outlined">
                mark_email_unread
              </span>
            }
          >
            <h4>Get Job Offers</h4>
            <span>straight in your inbox</span>
          </IconListItem>

          <IconListItem
            icon={<span className="material-symbols-outlined">language</span>}
          >
            <h4>Find Freelancers</h4>
            <span>to email in minutes</span>
          </IconListItem>
        </IconList>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper className="pt-1 pt-md-2">
        <div className="note-block note-block--grey">
          <h4>Welcome to</h4>
          <h1>Freelancers Directory</h1>

          <p>
            FD is a platform built by freelancers, for freelancers. More
            specifically, it is deliberately designed to address the needs of
            freelancers in the creative industry, as well as some areas of the
            hospitality sector.
          </p>

          <p>
            For now, we’ve decided to focus only on these sectors, but once
            we’ve mastered serving this demographic, we plan to expand into
            other, similar industries. Likewise, our current focus is on job
            postings, but in the future, we hope to offer other types of ads,
            such as studio and location rentals, equipment listings, and more.
          </p>

          <p>
            Please note that our official launch date is set for{" "}
            <b>November 15th</b>, and whilst we’re excited to bring you the full
            experience, some features may not be publicly available until then.
            We appreciate your patience and support as we continue to develop a
            platform that serves freelancers like you.
          </p>
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper className="home-page cols-2 sm-flex-col-reverse md-flex-col-reverse">
        <div className="col col-sm-100 col-lg-40 col-xl-60 pl-lg-5">
          <h1>Collaboration in Motion</h1>
          <h4>When the right people connect, great work happens.</h4>
          <br />
          <p>
            Success comes from finding the right match. Together, we build
            solutions that move ideas forward.
          </p>
          <p>
            <Link to="/content/resources/how-does-it-work">
              See how it all comes together...
            </Link>
          </p>
        </div>
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancersCommunity} />
        </div>
      </WebsiteSectionWrapper>

      {/* <WebsiteSectionWrapper>
        <p>
          Freelancing is about more than just completing tasks—it's about
          creating meaningful partnerships. When freelancers and clients align,
          ideas flow, creativity thrives, and projects come to life. The right
          collaboration isn’t just about fitting in; it’s about elevating each
          other’s work to achieve something remarkable.
        </p>
      </WebsiteSectionWrapper> */}

      <WebsiteSectionWrapper className="home-page info-card info-card--reversed info-card--primary-light cols-2">
        <div className="col image-section col-sm-100 col-lg-40 col-xl-50">
          <img src={FreelancersWorking} />
        </div>
        <div className="col text-section col-sm-100 col-lg-60 col-xl-50">
          <h1>Work Without the Hustle</h1>
          <h4>Focus on what truly matters—your craft.</h4>
          <br />
          <p>
            Focus on your passion whilst the right job alerts come to you. No
            more wasting hours sifting through irrelevant listings or stressing
            over missed opportunities.{" "}
          </p>
          <p>
            With personalised job alerts, you can spend more time perfecting
            your skills and less time searching, knowing that the right projects
            will land right in your inbox when they match your expertise.
          </p>
          <p>
            <Link to="/content/resources/our-motivation">
              Find out how we can help you...
            </Link>
          </p>
        </div>
      </WebsiteSectionWrapper>

      {/* <WebsiteSectionWrapper>
        <p>
          In the world of freelancing, it's easy to get caught up in the job
          search grind, scrolling through countless listings. But the real joy
          comes from doing what you love, free from the stress of finding the
          perfect opportunity.
        </p>
        <p>
          Embrace a lifestyle where you can concentrate on your projects,
          collaborate with like-minded individuals, and let the right
          job-finding service deliver relevant opportunities straight to your
          inbox.
        </p>
      </WebsiteSectionWrapper> */}

      <WebsiteSectionWrapper className="home-page cols-2 sm-pt-1 md-pt-1 lg-pt-2 sm-flex-col-reverse md-flex-col-reverse">
        <div className="col col-sm-100 col-lg-40 col-xl-60 pl-lg-5 sm-pt-0">
          <h1>Effortless Access to Top Talent</h1>
          <h4>Find the right talent and reach out in minutes.</h4>
          <br />
          <p>
            Finding the right freelance talent doesn’t have to be complicated.
            Our platform allows advertisers to swiftly search through a diverse
            pool of professionals tailored to their project requirements.
          </p>
          <p>
            <Link to="/content/services/pricing">
              And it’s designed to be affordable for everyone...
            </Link>
          </p>
        </div>
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancerSmartSearch} />
        </div>
      </WebsiteSectionWrapper>

      {/* <WebsiteSectionWrapper>
        <p>
          In just a few clicks, you can compile a list of suitable freelancers
          and contact them directly, making the hiring process fast and
          efficient so you can focus on bringing your ideas to life.
        </p>
      </WebsiteSectionWrapper> */}
    </>
  );
}

export default HomePage;
