import React from "react";
import ContentLayout from "../../layout/ContentLayout";

function PrivacyPolicyContent() {
  return (
    <ContentLayout>
      <div>
        <h1>Privacy and Data Protection Policy</h1>
        <h4>
          in accordance with the EU General Data Protection Regulation (GDPR)
        </h4>
      </div>
      <br />
      <br />
      <div>
        <h3>Contact details</h3>
        <p>
          <b>Email:</b> team@freelancers.directory
        </p>
      </div>

      <div>
        <h3>What information we collect, use, and why</h3>

        <p>
          We collect or use the following information to{" "}
          <b>provide services and goods, including delivery:</b>
          <ul>
            <li>Names and contact details</li>
            <li>Location details</li>
            <li>Year of birth</li>
            <li>Purchase or account history</li>
            <li>Account information</li>
            <li>
              Website user information (including user journeys and cookie
              tracking)
            </li>
            <li>Photographs</li>
          </ul>
        </p>

        <p>
          We also collect or use the following information to{" "}
          <b>provide services and goods, including delivery:</b>
          <ul>
            <li>Racial or ethnic origin</li>
            <li>Religious or philosophical beliefs</li>
            <li>Sexual orientation information</li>
          </ul>
        </p>

        <p>
          We collect or use the following information for{" "}
          <b>the operation of customer accounts and guarantees:</b>
          <ul>
            <li>Names and contact details</li>
            <li>Addresses</li>
            <li>Purchase history</li>
            <li>Account information, including registration details</li>
            <li>Information used for security purposes</li>
            <li>Marketing preferences</li>
          </ul>
        </p>

        <p>
          We also collect or use the following information for{" "}
          <b>the operation of customer accounts and guarantees:</b>
          <ul>
            <li>Racial or ethnic origin</li>
            <li>Religious or philosophical beliefs</li>
            <li>Sexual orientation information</li>
          </ul>
        </p>

        <p>
          We collect or use the following information to{" "}
          <b>prevent, detect, investigate or prosecute crimes:</b>
          <ul>
            <li>Names and contact information</li>
            <li>Customer or client accounts and records</li>
          </ul>
        </p>

        <p>
          We collect or use the following information for{" "}
          <b>service updates or marketing purposes:</b>
          <ul>
            <li>Names and contact details</li>
            <li>Addresses</li>
            <li>Marketing preferences</li>
            <li>Location data</li>
            <li>IP addresses</li>
            <li>Website and app user journey information</li>
          </ul>
        </p>

        <p>
          We collect or use the following information for{" "}
          <b>research or archiving purposes:</b>
          <ul>
            <li>Names and contact details</li>
            <li>Addresses</li>
            <li>Location data</li>
            <li>Purchase or viewing history</li>
            <li>IP addresses</li>
            <li>Website and app user journey information</li>
            <li>Personal information used for administration of research</li>
            <li>Personal information used for the purpose of research</li>
            <li>Records of consent, where appropriate</li>
          </ul>
        </p>

        <p>
          We also collect or use the following information for{" "}
          <b>research or archiving purposes:</b>
          <ul>
            <li>Racial or ethnic origin</li>
            <li>Religious or philosophical beliefs</li>
            <li>Sexual orientation information</li>
          </ul>
        </p>

        <p>
          We collect or use the following information to{" "}
          <b>comply with legal requirements:</b>
          <ul>
            <li>Name</li>
            <li>Contact information</li>
            <li>Identification documents</li>
            <li>Financial transaction information</li>
          </ul>
        </p>

        <p>
          We collect or use the following personal information for{" "}
          <b>dealing with queries, complaints or claims:</b>
          <ul>
            <li>Names and contact details</li>
            <li>Address</li>
            <li>Payment details</li>
            <li>Account information</li>
            <li>Purchase or service history</li>
            <li>Customer or client accounts and records</li>
            <li>Financial transaction information</li>
          </ul>
        </p>
      </div>

      <div>
        <h3>Lawful bases and data protection rights </h3>
        <p>
          Under UK data protection law, we must have a “lawful basis” for
          collecting and using your personal information. There is a list of
          possible lawful bases in the UK GDPR. You can find out more about
          lawful bases on the ICO’s website.
        </p>

        <p>
          Which lawful basis we rely on may affect your data protection rights
          which are in brief set out below. You can find out more about your
          data protection rights and the exemptions which may apply on the ICO’s
          website:
        </p>

        <p>
          <ul>
            <li>
              <b>Your right of access </b>- You have the right to ask us for
              copies of your personal information. You can request other
              information such as details about where we get personal
              information from and who we share personal information with. There
              are some exemptions which means you may not receive all the
              information you ask for.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#roa"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>

            <li>
              <b>Your right to rectification </b>- You have the right to ask us
              to correct or delete personal information you think is inaccurate
              or incomplete.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtr"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>

            <li>
              <b>Your right to erasure </b>- You have the right to ask us to
              delete your personal information.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rte"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>

            <li>
              <b>Your right to restriction of processing </b>- You have the
              right to ask us to limit how we can use your personal information.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtrop"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>

            <li>
              <b>Your right to object to processing </b>- You have the right to
              object to the processing of your personal data.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rto"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>

            <li>
              <b>Your right to data portability </b>- You have the right to ask
              that we transfer the personal information you gave us to another
              organisation, or to you.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtdp"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>

            <li>
              <b>Your right to withdraw consent </b>- When we use consent as our
              lawful basis you have the right to withdraw your consent at any
              time.{" "}
              <a
                href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtwc"
                target="_blank"
              >
                You can read more about this right here
              </a>
              .
            </li>
          </ul>
        </p>

        <p>
          If you make a request, we must respond to you without undue delay and
          in any event within one month.
        </p>

        <p>
          To make a data protection rights request, please contact us using the
          contact details at the top of this privacy notice.
        </p>
      </div>

      <div>
        <h3>Our lawful bases for the collection and use of your data</h3>

        <p>
          Our lawful bases for collecting or using personal information to{" "}
          <b>provide services and goods</b> are:
        </p>

        <ul>
          <li>
            Consent - we have permission from you after we gave you all the
            relevant information. All of your data protection rights may apply,
            except the right to object. To be clear, you do have the right to
            withdraw your consent at any time.
          </li>
          <li>
            Contract – we have to collect or use the information so we can enter
            into or carry out a contract with you. All of your data protection
            rights may apply except the right to object.
          </li>
        </ul>

        <p>
          Our lawful bases for collecting or using personal information for{" "}
          <b>the operation of customer accounts and guarantees</b> are:
        </p>

        <ul>
          <li>
            Consent - we have permission from you after we gave you all the
            relevant information. All of your data protection rights may apply,
            except the right to object. To be clear, you do have the right to
            withdraw your consent at any time.
          </li>
          <li>
            Contract – we have to collect or use the information so we can enter
            into or carry out a contract with you. All of your data protection
            rights may apply except the right to object.
          </li>
        </ul>

        <p>
          Our lawful bases for collecting or using personal information to{" "}
          <b>prevent, detect, investigate or prosecute crimes</b> are:
        </p>

        <ul>
          <li>
            Legal obligation – we have to collect or use your information so we
            can comply with the law. All of your data protection rights may
            apply, except the right to erasure, the right to object and the
            right to data portability.
          </li>
        </ul>

        <p>
          Our lawful bases for collecting or using personal information for{" "}
          <b>service updates or marketing purposes</b> are:
        </p>

        <ul>
          <li>
            Consent - we have permission from you after we gave you all the
            relevant information. All of your data protection rights may apply,
            except the right to object. To be clear, you do have the right to
            withdraw your consent at any time.
          </li>
        </ul>

        <p>
          Our lawful bases for collecting or using personal information for{" "}
          <b>research or archiving purposes</b> are:
        </p>

        <ul>
          <li>
            Consent - we have permission from you after we gave you all the
            relevant information. All of your data protection rights may apply,
            except the right to object. To be clear, you do have the right to
            withdraw your consent at any time.
          </li>
        </ul>

        <p>
          Our lawful bases for collecting or using personal information for{" "}
          <b>legal requirements</b> are:
        </p>

        <ul>
          <li>
            Legal obligation – we have to collect or use your information so we
            can comply with the law. All of your data protection rights may
            apply, except the right to erasure, the right to object and the
            right to data portability.
          </li>
        </ul>

        <p>
          Our lawful bases for collecting or using personal information for{" "}
          <b>dealing with queries, complaints or claims</b> are:
        </p>

        <ul>
          <li>
            Contract – we have to collect or use the information so we can enter
            into or carry out a contract with you. All of your data protection
            rights may apply except the right to object.
          </li>
          <li>
            Legal obligation – we have to collect or use your information so we
            can comply with the law. All of your data protection rights may
            apply, except the right to erasure, the right to object and the
            right to data portability.
          </li>
        </ul>
      </div>

      <div>
        <h3>Where we get personal information from</h3>
        <p>Directly from you</p>
      </div>

      <div>
        <h3>How long we keep information</h3>
        <p>All details are kept for as long as needed or legally required.</p>
      </div>

      <div>
        <h3>Who we share information with</h3>

        <p>Others we share personal information with:</p>

        <p>
          <ul>
            <li>
              Organisations we need to share information with for safeguarding
              reasons
            </li>
            <li>Professional or legal advisors</li>
            <li>Financial or fraud investigation authorities</li>
            <li>Relevant regulatory authorities</li>
            <li>External auditors or inspectors</li>
            <li>
              Organisations we’re legally obliged to share personal information
              with
            </li>
            <li>Suppliers and service providers</li>
          </ul>
        </p>
      </div>

      <div>
        <h3>Sharing information outside the UK</h3>

        <p>
          Where necessary, we may transfer personal information outside of the
          UK. When doing so, we comply with the UK GDPR, making sure appropriate
          safeguards are in place.
        </p>

        <p>
          For further information or to obtain a copy of the appropriate
          safeguard for any of the transfers below, please contact us using the
          contact information provided above.
        </p>

        <p>
          <b>Organisation name:</b> Alphabet Inc./Google LLC
        </p>
        <p>
          <b>Category of recipient:</b> Cloud computing and online services
        </p>
        <p>
          <b>Country the personal information is sent to:</b> United States of
          America
        </p>
        <p>
          <b>How the transfer complies with UK data protection law:</b> The
          country or sector has a UK data bridge (also known as Adequacy
          Regulations)
        </p>
      </div>

      <div>
        <h3>How to complain</h3>

        <p>
          If you have any concerns about our use of your personal data, you can
          make a complaint to us using the contact details at the top of this
          privacy notice.
        </p>

        <p>
          If you remain unhappy with how we’ve used your data after raising a
          complaint with us, you can also complain to the ICO.
        </p>

        <p>
          The ICO’s address:
          <br />
          Information Commissioner’s Office
          <br />
          Wycliffe House
          <br />
          Water Lane
          <br />
          Wilmslow
          <br />
          Cheshire
          <br />
          SK9 5AF
          <br />
          Helpline number: 0303 123 1113
          <br />
          Website:{" "}
          <a href="https://ico.org.uk/make-a-complaint/" target="_blank">
            https://www.ico.org.uk/make-a-complaint
          </a>
        </p>
      </div>

      <div>
        <h3>Last updated</h3>

        <p>27 August 2024</p>
      </div>
    </ContentLayout>
  );
}

export default PrivacyPolicyContent;
