import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import FreelancerSmartSearch from "../../assets/images/FreelancerSmartSearch.svg";
import { SignInForm } from "../../pages/SignInPage";

const steps = (
  <ol className="max-width-100 mt-0">
    <li>
      <span>
        <h4>Sign In</h4> – No need for a separate account to post ads. Your
        freelancer account works perfectly. If you’re new, just sign up—it’s
        free and takes only a minute or two.
      </span>
    </li>
    <li>
      <span>
        <h4>Search & Filter</h4> – Head to the ‘Post an Ad’ page, where you can
        search and filter our contact list based on skills, location, and more.
      </span>
    </li>
    <li>
      <span>
        <h4>Draft Your Ad</h4> – Once you’ve applied filters, you’ll see the
        total number of matching contacts (but not their details). If the number
        looks good, continue to the next page to draft your ad.
      </span>
    </li>
    <li>
      <span>
        <h4>Enter Contact Details</h4> – After explaining what you need, provide
        your contact details. This info will be sent to all matching freelancers
        so they can reach out to you directly.
      </span>
    </li>
    <li>
      <span>
        <h4>Send</h4> – Double-check everything, and when you’re happy with it,
        hit send! That’s it—you’re done! Now, sit back and wait for the emails
        and phone calls to roll in.
      </span>
    </li>
  </ol>
);

function PostAnAdvertContent() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancerSmartSearch} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h1>I Need Freelancers!</h1>
          <h4>How Do I Email Them?</h4>
          <br />
          <p>
            Posting an advert is simple and hassle-free. With just a few clicks,
            you can filter freelancers based on skills, location, and other
            criteria, and send your ad directly to the most relevant matches.
          </p>
          {/* <p>
            Provide a brief description about your project, enter your contact
            information, and wait for freelancers to reach out to you. It’s
            fast, efficient, and puts the right people in your inbox.
          </p> */}
        </div>
      </WebsiteSectionWrapper>
      <WebsiteSectionWrapper className="home-page cols-2 sm-flex-col-reverse md-flex-col-reverse flex-align-start-f">
        <div className="col col-sm-100 col-lg-40 col-xl-40 md-pt-1">
          <div className="form-container form-container--neutral-grey sm-mt-1">
            <h1>Sign-in</h1>
            <SignInForm />
          </div>
        </div>
        <div className="col col-sm-100 col-lg-60 col-xl-60 pt-1">{steps}</div>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default PostAnAdvertContent;
