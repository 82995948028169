import React, { useState } from "react";
import Button from "./form/Button";
import { Link } from "react-router-dom";

export function CardStep({ children }) {
  return (
    <div className="card__step" type="CardStep">
      {children}
    </div>
  );
}

function Card({ children, title, steps, className }) {
  const [step, setStep] = useState(0);
  const maxSteps = steps.length;
  const disablePrev = step == 0;
  const disableNext = step == maxSteps - 1;

  function showStep(direction) {
    if (direction == "next") {
      setStep(step + 1);
    }

    if (direction == "prev") {
      setStep(step - 1);
    }
  }

  const nextButtonLabel = (
    <>
      Next
      <span className="material-symbols-outlined icon-next"></span>
    </>
  );

  const prevButtonLabel = (
    <>
      <span className="material-symbols-outlined icon-prev"></span>
      Prev
    </>
  );

  function getBreadcrumbs(currentStep) {
    const bcSteps = [];

    const bcOnClick = (e, s) => {
      e.preventDefault();

      setStep(s);
    };

    steps.forEach((s, i) => {
      const bcLink =
        currentStep == i ? (
          <span className="active" key={s.title}>
            {s.title}
          </span>
        ) : (
          <Link to="/" key={s.title} onClick={(e) => bcOnClick(e, i)}>
            {s.title}
          </Link>
        );

      bcSteps.push(bcLink);

      if (i < steps.length - 1) {
        bcSteps.push(<span key={`divider-${i}`}>&nbsp;/&nbsp;</span>);
      }
    });

    return bcSteps;
  }

  return (
    <div className={`card ${className}`}>
      <div className="card__header">
        <div>
          <h4 className="card__title">{title}</h4>
          <br />
          {/* <a href="#">What is this for?</a> */}
        </div>
        <span className="card__step-indicator">
          [{` ${step + 1}/${maxSteps} `}]
        </span>
      </div>
      <div className="card__breadcrumbs">{getBreadcrumbs(step)}</div>
      <div className="card__content">
        {children}
        {steps[step].content}
      </div>
      {/* <div className="card__buttons">
        <Button
          label={prevButtonLabel}
          onClick={() => showStep("prev")}
          disabled={disablePrev}
          className="card__buttons__prev"
        />
        <Button
          label={nextButtonLabel}
          onClick={() => showStep("next")}
          disabled={disableNext}
          className="card__buttons__next"
        />
      </div> */}
    </div>
  );
}

export default Card;
