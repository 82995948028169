import React, { useEffect } from "react";
import ContentLayout from "../layout/ContentLayout";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import { addHBForm } from "../utils/hubspot";

function ContactUsPage() {
  useEffect(() => {
    addHBForm("31367490-2af6-425f-b0cb-7fae529ed659", "#contact-us-form");
  }, [window.hbspt]);

  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2 flex-direction-row-f">
        <div className="col col-sm-100 col-lg-60 col-xl-60 align-self-start">
          <div className="website-form-container">
            <h1>Get in Touch</h1>
            <p>
              We may be a small team, but we’d love to hear from you! Whether
              you have questions, suggestions, or just want to say hello, we’ll
              do our best to respond within a few hours, max.
            </p>
            <p>
              Feel free to use the form below or drop us an email directly at{" "}
              <b>team@freelancers.directory</b>. We’re here to help!
            </p>
          </div>
        </div>
        <div className="col col-sm-100 col-md-70 col-lg-40 col-xl-40">
          <div id="contact-us-form"></div>
        </div>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default ContactUsPage;
