import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import WebsiteSectionWrapper from "./WebsiteSectionWrapper";
import WebsiteHeaderNav from "../components/nav/WebsiteHeaderNav";
import ScrollToTop from "./ScrollToTop";

function WebsiteLayout() {
  const [displayNav, setDisplayNav] = useState(false);

  return (
    <div className="website-layout">
      <ScrollToTop />
      <div className="website-header">
        <WebsiteSectionWrapper>
          <div className="website-header__contents">
            <div className="website-header__logo">
              <Link to="/">FD</Link>
            </div>
            <span
              onClick={() => setDisplayNav(!displayNav)}
              className="material-symbols-outlined website-header-nav__menu-icon"
            ></span>
          </div>

          {displayNav && (
            <WebsiteHeaderNav mobile closeMenu={() => setDisplayNav(false)} />
          )}
          <WebsiteHeaderNav />
        </WebsiteSectionWrapper>
      </div>
      <div className="website-page-content">
        <Outlet />
      </div>
      <div className="website-footer">
        <WebsiteSectionWrapper>
          <div>
            <div className="website-footer__menu">
              <div>
                <h4 className="website-footer__menu__header">Services</h4>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/services/membership"
                      className="website-footer__menu__item"
                    >
                      Membership
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/services/posting-an-advert"
                      className="website-footer__menu__item"
                    >
                      Post an Advert
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/services/pricing"
                      className="website-footer__menu__item"
                    >
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <h4 className="website-footer__menu__header">Legal</h4>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/legal/terms-of-service"
                      className="website-footer__menu__item"
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/legal/privacy-policy"
                      className="website-footer__menu__item"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/legal/web-cookies"
                      className="website-footer__menu__item"
                    >
                      Web Cookies
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/content/legal/google-analytics"
                      className="website-footer__menu__item"
                    >
                      Google Analytics
                    </Link>
                  </li> */}
                </ul>
              </div>

              <div>
                <h4 className="website-footer__menu__header">Resources</h4>
                <ul className="no-style">
                  <li>
                    <Link
                      to="/content/resources/how-does-it-work"
                      className="website-footer__menu__item"
                    >
                      How does it work?
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/content/resources/frequently-asked-questions"
                      className="website-footer__menu__item"
                    >
                      F.A.Q.
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/content/resources/why-freelancers-directory"
                      className="website-footer__menu__item"
                    >
                      Why FD?
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/resources/our-motivation"
                      className="website-footer__menu__item"
                    >
                      Our Motivation
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/content/resources/supported-jobs"
                      className="website-footer__menu__item"
                    >
                      Supported Jobs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content/resources/supported-locations"
                      className="website-footer__menu__item"
                    >
                      Supported Locations
                    </Link>
                  </li> */}
                </ul>
              </div>

              <div>
                <h4 className="website-footer__menu__header">Help</h4>
                <ul className="no-style">
                  <li>
                    <Link to="/support" className="website-footer__menu__item">
                      Support
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/feedback"
                      className="website-footer__menu__item"
                    >
                      Feedback
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/contact-us"
                      className="website-footer__menu__item"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className="website-footer__menu__item">
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="website-footer__notes">
              <div className="website-footer__cookies">
                <h4>Cookie Notice</h4>
                We use cookies to improve your experience on our website,
                personalise content, and analyse our traffic. By continuing to
                use this website, you agree to our use of cookies in accordance
                with our{" "}
                <Link to="/content/legal/web-cookies">Cookie Policy</Link>.
              </div>

              <div className="website-footer__logo">
                Freelancers Directory
                <span className="website-footer__copyright">
                  Copyright &copy; 2024 All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
        </WebsiteSectionWrapper>
      </div>
    </div>
  );
}

export default WebsiteLayout;
