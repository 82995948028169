import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import FreelancersCommunity from "../../assets/images/FreelancersCommunity.svg";
import RelatedLinks from "../../blocks/RelatedLinks";

function MotivationContent() {
  const RelatedLinksArray = [
    {
      text: "Why Freelancers Directory?",
      link: "/content/resources/why-freelancers-directory",
    },
    // {
    //   text: "F.A.Q.",
    //   link: "/content/resources/frequently-asked-questions",
    // },
  ];
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancersCommunity} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h1>What Drives Us Forward</h1>
          {/* <h4>Empowering Creative Freelancers with a Smarter Platform.</h4> */}
          <h4>And The Problem We Are Trying To Solve</h4>
          <br />
          <p>
            At Freelancers Directory, we know firsthand the challenges faced by
            freelancers, especially those in the creative industry. We’ve
            experienced the frustration of navigating inefficient job boards,
            dealing with platforms that take hefty fees, and the constant hustle
            of searching for work.
          </p>
          <p>
            Our motivation stems from a desire to create something better—a
            platform that empowers freelancers, simplifies the job search
            process, and connects talent with opportunities in a way that works
            for everyone. This isn’t just a service; it’s a solution built by
            freelancers, for freelancers.
          </p>
        </div>
      </WebsiteSectionWrapper>

      <WebsiteSectionWrapper>
        <h4>The Problem With Traditional Job Listing Websites</h4>
        <p>
          Creative freelance work often doesn’t fit into the structure of
          traditional job listings. Most of these projects are short-term,
          sometimes lasting just a few hours or days, making full-time or
          part-time job boards unsuitable.
        </p>
        <p>
          Additionally, many gigs come up last minute—like when someone on a
          crew has to cancel unexpectedly and a replacement is needed ASAP. On
          top of that, the tight budgets or unpaid collaborations that are
          common in this field don’t align with existing platforms that charge
          hefty fees for posting job ads.
        </p>

        <blockquote>
          <p>
            Whilst there are some project-based job platforms for freelancers,
            they often come with their own set of flaws that make them just as
            unsuitable as traditional job listing sites for creative
            freelancers.
          </p>
        </blockquote>

        <h4>The Problem With Freelancing Job Listing Websites</h4>
        <p>
          One of the biggest issues is that these platforms typically charge
          freelancers, not advertisers. This leads to a lot of job ads from
          clients who aren’t serious about hiring but are merely looking for
          price estimates or free advice. Freelancers end up wasting time on
          leads that never materialize into real opportunities, all whilst being
          asked to pay to contact these potential clients.
        </p>
        <p>
          Another major issue is the middleman structure of these platforms.
          They don’t just take a significant portion of the freelancer’s
          earnings, but they also lock you into using their system. Freelancers
          are forced to create profiles, upload samples, and operate entirely
          within the platform, which can become a hassle if you're juggling
          accounts on multiple sites.
        </p>
        <p>
          This rigid setup also limits flexibility, pushing freelancers to
          conform to specific categories and formats to be "discoverable." The
          result? A lot of time spent optimizing thumbnails or crafting
          clickbait-like descriptions to stand out in a crowded search result
          page, rather than focusing on the actual work.
        </p>
        <p>
          Lastly, the visibility and public nature of these platforms can be
          problematic. Freelancers are encouraged to display all their personal
          and professional information—photos, portfolios, social media links—in
          a way that makes it hard to control privacy.
        </p>
        <p>
          This forced transparency puts freelancers in a tough spot. They may
          feel pressured to only take high-profile or well-paid jobs to maintain
          a certain image, even if they need to accept smaller, lower-paying
          gigs during slower periods. No freelancer should have to publicly
          justify why they’re taking on lower-budget work, and the overly public
          nature of these platforms makes that unavoidable.
        </p>

        <blockquote>
          <p>
            Because traditional job listing sites don’t fit the needs of
            creative freelancers, and existing project-based platforms come with
            their own set of problems, many freelancers turn to unorthodox
            methods to find work—primarily through social media.
          </p>
        </blockquote>

        <h4>A Suboptimal Workaround</h4>
        <p>
          Whilst social media can be useful, it’s far from efficient. There’s no
          smart way to filter through the constant stream of posts, many of
          which urgently need someone but get buried in the noise. This creates
          a very inefficient job-hunting process that consumes a lot of time and
          energy.
        </p>
        <p>
          What’s more, the mental strain caused by this disorganised,
          scattershot approach is significant. This is exactly why we have put a
          lot of effort into building this platform.
        </p>

        <h4>A Solution That Works For Everyone</h4>
        <p>
          At Freelancers Directory, we’ve created a platform designed with the
          needs of freelancers and their clients at its core. Our approach is
          simple yet effective: to provide a space where freelancers can easily
          connect with opportunities and where those seeking freelance talent
          can find the perfect match without hassle.
        </p>
        <p>
          Our platform operates with a strong emphasis on privacy and
          efficiency. We understand that everyone wants control over their
          information, which is why your details remain completely confidential.
          Advertisers never access your personal data; instead, they filter
          through our directory based on specific criteria, such as location and
          skills. The only thing they see is the number of freelancers who match
          their search. This approach ensures that your privacy is always
          protected, and you only engage with opportunities that genuinely
          interest you.
        </p>
        <p>
          We believe in making things as effortless as possible for freelancers.
          Once you’ve signed up and completed your profile, there’s no need to
          constantly check the platform. We handle that for you. Whenever a job
          matches your criteria, we’ll send you an email with all the
          information you need to connect with the advertiser directly. This
          way, you can focus on your work while we keep an eye out for the right
          opportunities for you.
        </p>
        <p>
          For those looking to hire, the process is just as streamlined.
          Advertisers can refine their search criteria to pinpoint exactly the
          type of freelancer they need. Once they’re satisfied with the results,
          they can craft and send their ad directly to the freelancers who match
          their needs, without ever seeing personal details.
        </p>

        <p>
          As we continue to grow, we’re committed to expanding our features and
          reaching new areas of the freelance world. Our aim is to evolve
          alongside our users, ensuring that Freelancers Directory remains a
          valuable and trusted resource for both freelancers and those who work
          with them.
        </p>
      </WebsiteSectionWrapper>

      <RelatedLinks links={RelatedLinksArray} />
    </ContentLayout>
  );
}

export default MotivationContent;
