import React, { useEffect } from "react";
import ContentLayout from "../layout/ContentLayout";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import { addHBForm } from "../utils/hubspot";

function SupportPage() {
  useEffect(() => {
    addHBForm("2da0617b-771e-4d93-988e-2c2b215b53aa", "#contact-us-form");
  }, [window.hbspt]);

  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2 flex-direction-row-f">
        <div className="col col-sm-100 col-lg-60 col-xl-60 align-self-start">
          <div className="website-form-container">
            <h1>Need Help? We're Here for You</h1>
            <p>
              If you're experiencing an issue or need assistance, this is the
              best way to get in touch. Simply fill out the support form, and
              we’ll get back to you as soon as possible—usually within a few
              hours. We’re committed to ensuring everything runs smoothly, so
              don’t hesitate to reach out.
            </p>
            <p>
              You can also email us directly at{" "}
              <b>team@freelancers.directory</b> if you prefer. We’re here to
              support you every step of the way.
            </p>
          </div>
        </div>
        <div className="col col-sm-100 col-md-70 col-lg-40 col-xl-40">
          <div id="contact-us-form"></div>
        </div>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default SupportPage;
