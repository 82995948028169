import React, { useContext } from "react";
import { UserContext } from "../../utils/context";
import { requestAutoSave } from "../../layout/AppLayout";
import Form, { FormField } from "../../components/form/Form";
import { CardStep } from "../Card";

function CurrentLocationForm({ locations }) {
  const { user, setUser } = useContext(UserContext);
  const autoSave = () => requestAutoSave(setUser);
  const defaultSelect = { label: "-- select --", id: "--" };

  function update(key, value) {
    setUser((prev) => {
      const updatedData = {
        ...prev,
      };

      if (!updatedData?.current_location) {
        updatedData.current_location = {};
      }

      updatedData.current_location[key] = value;

      return updatedData;
    });

    autoSave();
  }

  function getCountryDropDownList() {
    function onChange(e) {
      const countryCode = e.target.value;
      update("country", countryCode);
    }

    const items = [defaultSelect, ...locations].map((el) => (
      <option value={el.id} key={el.id}>
        {el.label}
      </option>
    ));

    return (
      <select
        name="country"
        value={user?.current_location?.country}
        onChange={onChange}
      >
        {items}
      </select>
    );
  }

  function getLocationInput(locations) {
    return (
      <select name="location" value="--">
        <option>-- Select --</option>
        {locations &&
          locations.map((el) => {
            return (
              <option value={el.id} key={el.id}>
                {el.label}
              </option>
            );
          })}
      </select>
    );
  }

  return (
    <CardStep>
      <Form>
        <FormField
          label="Location"
          desc="(Required)"
          input={getCountryDropDownList()}
        />
      </Form>
    </CardStep>
  );
}

export default CurrentLocationForm;
