import React, { useContext, useState } from "react";
import { updateUserProfile, loadSignedInUserData } from "../utils/auth";
import ContactDetailsForm from "../components/user/ContactDetailsForm";
import LocationDetailsForm from "../components/user/LocationDetailsForm";
import RoleDetailsForm from "../components/user/RoleDetailsForm";
import { UserContext } from "../utils/context";
import Form, { FormButtons } from "../components/form/Form";
import Button from "../components/form/Button";

function DashboardPage() {
  const { user, setUser } = useContext(UserContext);
  const [saveChanges, setSaveChanges] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const disableForm = Object.keys(formErrors).length > 0;

  function formErrorRegister(field, hasError) {
    const updated = { ...formErrors };

    if (hasError) {
      updated[field] = hasError;
    } else {
      delete updated[field];
    }

    setFormErrors(updated);
  }

  function handleSaveChanges() {
    if (disableForm) {
      console.log("form cannot be submitted right now.");
    }

    setSaveChanges(true);

    updateUserProfile(user.auth.accessToken, user).then((res) => {
      setSaveChanges(false);
    });
  }

  const errorList = (
    <div className="form__error-list">
      <p>There seem to be some errors in the form:</p>
      <ul>
        {Object.keys(formErrors).map((field) => (
          <li>
            <span>{field}:</span> {formErrors[field]}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="dashboard-page">
      <ContactDetailsForm formErrorRegister={formErrorRegister} />
      <LocationDetailsForm />
      <RoleDetailsForm className="last-child" />
      <Form className="dashboard-main-buttons">
        {disableForm && errorList}
        <FormButtons>
          <Button
            label="Save"
            onClick={handleSaveChanges}
            disabled={saveChanges || disableForm}
            role="primary-darker"
          />
          <Button label="Settings" link="/app/settings" role="light-blue" />
        </FormButtons>
      </Form>
    </div>
  );
}

export default DashboardPage;
