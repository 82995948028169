import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  getAuth,
  updatePassword,
} from "firebase/auth";
import axios from "axios";
import { firebaseAuth, googleAuth } from "../firebase.js";

// Firebase Authentication Errors
// ------------------------------
export function handleAuthError(authError, handleFunc) {
  switch (authError?.code) {
    case "auth/user-disabled":
      handleFunc("This account has been blocked.");
      break;

    case "auth/invalid-credential":
      handleFunc("The Username & Password does not match.");
      break;

    case "auth/invalid-email":
      handleFunc("The Email address seems to be invalid.");
      break;

    case "auth/missing-email":
      handleFunc("The Email address is required.");
      break;

    case "auth/missing-password":
      handleFunc("The Password is required.");
      break;

    case "auth/weak-password":
      handleFunc("Passwords should be at least 6 characters long");
      break;

    case "auth/email-already-in-use":
      handleFunc("This email address is already in use.");
      break;

    case "auth/requires-recent-login":
      handleFunc(
        "It seems your previous sgin-in has been expired. Please first sign-out, then sign-in and try again."
      );
      break;

    // default:
    //   handleFunc("It looks like there is an error:", authError?.code);
    //   break;
  }
}

// Get current user from Firebase auth
// -----------------------------------
export function getCurrentAuthUser() {
  const user = getAuth().currentUser;

  if (!user) {
    return null;
  }

  if (user?.uid && user?.accessToken) {
    return user;
  }

  return null;
}

// Firebase auth sign-out
// ----------------------
export const authSignOut = async () =>
  firebaseAuth.signOut().then(
    function () {
      // console.log("Signed Out");
    },
    function (error) {
      console.error("Sign Out Error", error);
    }
  );

// Firebase send passwordless-auth email
// -------------------------------------
export const sendPasswordlessEmail = async (email) => {
  const auth = getAuth();
  const options = {
    url: "http://freelancers.directory/passwordless",
    handleCodeInApp: true,
  };

  window.localStorage.setItem("emailForSignIn", email);

  return sendSignInLinkToEmail(auth, email, options);
};

// Firebase send forgot-password email
// -----------------------------------
export const sendForgotPasswordEmail = async (email) => {
  return sendPasswordResetEmail(getAuth(), email);
};

// Firebase Email & Pass Sign-up
// --------------------------
export async function emailSignUp(email, password) {
  const auth = getAuth();

  return createUserWithEmailAndPassword(auth, email, password)
    .then(async (result) => {
      const user = result.user;
      const userData = {
        ...result.user,
        ...getAdditionalUserInfo(result),
      };

      if (userData.isNewUser) {
        await createUserProfile(userData.accessToken)
          .then(() => {
            sendEmailVerification(result.user)
              .then(() => {})
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      }
    })
    .catch((error) => {
      return error;
    });
}

// Firebase Email & Pass Sign-in
// -----------------------------
export async function emailSignIn(email, password) {
  return signInWithEmailAndPassword(getAuth(), email, password);
}

// Firebase Change Password
// ------------------------
export async function changePassword(password) {
  return updatePassword(getAuth().currentUser, password);
}

// Firebase Google Auth
// ---------------------
export async function googleLogin(onSuccess, onFailure) {
  return signInWithPopup(firebaseAuth, googleAuth)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const userData = {
        ...result.user,
        ...getAdditionalUserInfo(result),
      };

      if (onSuccess) {
        await onSuccess(userData);
      }

      return userData;
    })
    .catch((error) => {
      if (onFailure) {
        onFailure(error);

        return null;
      }
    });
}

// Handle Google Auth
// -------------------
export async function handleGoogleLoginButton() {
  const onSuccess = async (userData) => {
    if (userData.isNewUser) {
      await createUserProfile(userData.accessToken);
    }
  };

  const onFailure = (error) => {
    console.log("google login failed:", error);
  };

  const userData = await googleLogin(onSuccess, onFailure);
  return userData;
}

// Handle Sign-out button
// ----------------------
export function handleSignOut() {
  return authSignOut();
}

// Sends a POST request via Axios
// ------------------------------
export const postCloudFunc = async (url, data) => {
  console.log("--- calling:", url);

  return axios
    .post(url, data)
    .then((res) => {
      console.log("post api results:", res);
      return res;
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
};

// Sets the user profile data in the React State
// ---------------------------------------------
export function setUserProfileDataInReactState(idToken, data, setUser) {
  const updatedUserData = {
    ...data,
    profileLoaded: Math.floor(Date.now() / 1000),
    unsaved: false,
  };

  delete updatedUserData.unsaved;

  updatedUserData.auth.accessToken = idToken;
  setUser(updatedUserData);
}

// Create a new user profile
// -------------------------
export async function createUserProfile(idToken) {
  const url = "https://createuserprofilev0-qxm247wkia-uc.a.run.app/";

  return axios
    .post(url, { idToken })
    .then((res) => {
      // console.log("create user profile api results:", res);
    })
    .catch((e) => console.log("create user profile axios error:", e));
}

// Update User Profile Info
// ------------------------
export function updateUserProfile(token, profile) {
  return axios.post(
    "https://updateuserprofiledatav0-qxm247wkia-uc.a.run.app/",
    {
      idToken: token,
      profileData: profile,
    }
  );
}

// Loads user data into the browser
// --------------------------------
export async function loadSignedInUserData(idToken, setUser, afterDone) {
  const url = "https://loadsignedinuserdatav0-qxm247wkia-uc.a.run.app/";

  return axios
    .post(url, { idToken })
    .then((res) => {
      setUserProfileDataInReactState(
        idToken,
        res.data.userData,
        setUser,
        afterDone
      );
    })
    .catch((error) => console.log(error));
}
