import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleAuthError, sendForgotPasswordEmail } from "../utils/auth";
import Form, {
  FormButtons,
  FormField,
  FormMessage,
} from "../components/form/Form";
import Button from "../components/form/Button";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import AuthProviderButtons from "../components/auth/AuthProviderButtons";
import { isValidEmail } from "../utils/validate";
import DidYouKnow from "../blocks/DidYouKnow";

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [waitForAPI, setWaitForAPI] = useState(false);
  const navigate = useNavigate();

  function clearUp() {
    setEmailError("");
    setWaitForAPI(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setWaitForAPI(true);
    setFormError(null);

    if (!email.trim()) {
      clearUp();
      setEmailError("Email address is required.");
      return false;
    } else {
      const validateEmail = isValidEmail(email);
      if (!validateEmail.passed) {
        clearUp();
        setEmailError(validateEmail.error.message);
        return false;
      }

      setEmailError("");
    }

    sendForgotPasswordEmail(email)
      .then(() => {
        navigate("/check-your-email");
      })
      .catch((authError) => {
        setWaitForAPI(false);

        console.log("authError:", authError);
        handleAuthError(authError, setFormError);
      });
  }

  const emailInput = (
    <input
      type="text"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  );

  return (
    <Form onSubmit={handleSubmit} className="first-child">
      {formError && (
        <FormMessage type="error">
          <span>{formError}</span>
        </FormMessage>
      )}
      <FormField label="Email" input={emailInput} error={emailError} />
      <FormButtons>
        <Button
          label={waitForAPI ? "Sending..." : "Send Password Reset Email"}
          type="submit"
          role="primary"
          disabled={waitForAPI}
        />
      </FormButtons>
      <FormButtons>
        <Button label="Sign in" onClick={() => navigate("/sign-in")} />
        <Button label="Sign up" onClick={() => navigate("/sign-up")} />
      </FormButtons>
    </Form>
  );
}

function ForgotPasswordPage() {
  return (
    <WebsiteSectionWrapper className="cols-2 align-top">
      <div className="col col-sm-100 col-lg-40 col-xl-40 pt-1 xl-pb-2">
        <h1>Forgot Password</h1>
        <ForgotPasswordForm />
        <AuthProviderButtons />
      </div>
      <div className="col col-sm-100 col-lg-60 col-xl-60 pt-1">
        <DidYouKnow />
      </div>
    </WebsiteSectionWrapper>
  );
}

export default ForgotPasswordPage;
