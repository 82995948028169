export function compareStrings(a, b) {
  a = a.toLowerCase();
  b = b.toLowerCase();

  return a < b ? -1 : a > b ? 1 : 0;
}

export function createOrderedList(obj) {
  return Object.keys(obj).sort((a, b) => {
    return a - b;
  });
}

export function timeInSecs() {
  return Math.floor(Date.now() / 1000);
}
