import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import FreelancersCommunity from "../../assets/images/FreelancersCommunity.svg";
import { SignUpForm } from "../../pages/SignUpPage";

const steps = (
  <ol className="max-width-100 mt-0">
    <li>
      <span>
        <h4>Sign Up</h4> – It’s free and it only takes less than a minute to
        create an account.
      </span>
    </li>
    <li>
      <span>
        <h4>Enter Your Email</h4> – Once logged in, enter your primary email
        address and double-check that it’s correct.
      </span>
    </li>
    <li>
      <span>
        <h4>Fill in Your Location</h4> – At a minimum, enter your country, but
        the more specific you are, the better your job matches will be!
      </span>
    </li>
    <li>
      <span>
        <h4>Choose Your Roles</h4> – Select at least one role from the Primary
        or Secondary Roles section, click on the Save button and you’re all set!
        <br />
        <br />
        You’ll now start receiving job opportunities that match your location
        and specified roles at the email address you specified earlier!
      </span>
    </li>
  </ol>
);

function MembershipContent() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancersCommunity} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h1>I'm a Freelancer!</h1>
          <h4>How Do I Get Job Notifications in My Email?</h4>
          <br />
          <p>
            Signing up is quick and easy, and it’s the first step to receiving
            job opportunities straight to your inbox. Once you create an account
            and fill in some basic details like your email, location, and
            preferred roles, you’ll start getting job notifications that match
            your skills and location.
          </p>

          {/* <p>
            No need to keep checking multiple platforms—just sit back, focus on
            your work, and let the opportunities come to you!
          </p> */}
        </div>
      </WebsiteSectionWrapper>
      <WebsiteSectionWrapper className="home-page cols-2 sm-flex-col-reverse md-flex-col-reverse flex-align-start-f">
        <div className="col col-sm-100 col-lg-40 col-xl-40 md-pt-1">
          <div className="form-container form-container--neutral-grey sm-mt-1">
            <h1>Sign-up</h1>
            <SignUpForm />
          </div>
        </div>
        <div className="col col-sm-100 col-lg-60 col-xl-60 pt-1">{steps}</div>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default MembershipContent;
