import React from "react";
import { compareStrings } from "../../utils/other_utils";
import Card from "../Card";
import PrimaryLocationForm from "./PrimaryLocationForm";
import CurrentLocationForm from "./CurrentLocationForm";

function LocationDetailsForm() {
  const locations = [
    { id: "gb", label: "United Kingdom" },
    { id: "fr", label: "France" },
    { id: "it", label: "Italy" },
    // { id: "au", label: "Australia" },
    { id: "ca", label: "Canada" },
    { id: "de", label: "Germany" },
    { id: "es", label: "Spain" },
    // { id: "us", label: "United States of America" },
  ].sort(function (a, b) {
    return compareStrings(a.label, b.label);
  });

  const cardSteps = [
    {
      title: "Primary Location",
      content: <PrimaryLocationForm locations={locations} />,
    },
    {
      title: "Current Location",
      content: <CurrentLocationForm locations={locations} />,
    },
  ];

  return (
    <Card title="Where" steps={cardSteps} className="location-details-card" />
  );
}

export default LocationDetailsForm;
