import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import { Link } from "react-router-dom";

function WebCookiesContent() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper>
        <h1>Web Cookies</h1>
        <h4>And our Cookie Policy</h4>

        <p>
          This page explains what cookies are, how we use them on our website,
          and how you can control their usage. By using our website, you consent
          to the use of cookies as described in this policy.
        </p>

        <h4>What Are Cookies?</h4>

        <p>
          Cookies are small text files that are stored on your device (computer,
          tablet, or mobile) when you visit a website. They help the website
          remember information about your visit, making it easier for you to
          interact with the website on subsequent visits.
        </p>

        <h4>Types of Cookies We Use</h4>

        <p>We use the following types of cookies:</p>

        <ul>
          <li>
            <b>Essential Cookies</b>
            <br />
            <p>
              These cookies are necessary for the website to function properly.
              They enable core functionalities such as security, network
              management, and accessibility. You can disable these cookies
              through your browser settings, but it may affect how the site
              functions.
            </p>
          </li>

          <li>
            <b>Performance Cookies</b>
            <br />
            <p>
              These cookies help us understand how visitors interact with our
              website by collecting and reporting information anonymously. They
              allow us to track the performance of our pages and improve user
              experience.
            </p>
          </li>

          <li>
            <b>Functionality Cookies</b>
            <br />
            <p>
              These cookies allow our website to remember choices you make, such
              as your username or language preferences, and provide enhanced,
              more personalised features.
            </p>
          </li>

          <li>
            <b>Targeting/Advertising Cookies</b>
            <br />
            <p>
              We do NOT use cookies for targeted advertising or to deliver
              personalised ads. Our cookies are not used by third-party
              services, including social media platforms, for advertising
              purposes. We only use cookies to improve your experience on our
              site and analyze website performance.
            </p>
          </li>
        </ul>

        <h4>Third-Party Cookies</h4>

        <p>
          In addition to our own cookies, we may also use various third-party
          cookies to report usage statistics and improve site functionality.
          However, we do NOT use or allow third-party advertisements on our
          platform. These third-party services have their own privacy policies,
          which we recommend you review.
        </p>

        <h4>How to Control Cookies</h4>

        <p>
          You can control and manage cookies via your Web Browser settings. Most
          web browsers allow you to control cookies through your browser
          settings. You can choose to block or delete cookies, but doing so may
          impact your experience on the site.
        </p>

        <h4>Changes to This Policy</h4>

        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. Please review this page regularly to stay informed about our
          use of cookies.
        </p>

        <h4>Contact Us</h4>

        <p>
          If you have any questions about our use of cookies, please contact us{" "}
          <Link to="/content/help/contact-us">here</Link>.
        </p>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default WebCookiesContent;
