import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { emailSignUp, handleAuthError } from "../utils/auth";
import Form, {
  FormButtons,
  FormField,
  FormMessage,
} from "../components/form/Form";
import Button from "../components/form/Button";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import AuthProviderButtons from "../components/auth/AuthProviderButtons";
import { checkPasswordStrength, isValidEmail } from "../utils/validate";
import DidYouKnow from "../blocks/DidYouKnow";

function EmailSignUpForm() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [pass, setPass] = useState("");
  const [passError, setPassError] = useState(null);
  const [rePass, setRePass] = useState("");
  const [formError, setFormError] = useState(null);
  const [waitForAPI, setWaitForAPI] = useState(false);
  const navigate = useNavigate();

  function clearUp() {
    setEmailError("");
    setPassError("");
    setWaitForAPI(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setWaitForAPI(true);
    setFormError(null);

    if (!email.trim()) {
      clearUp();
      setEmailError("Email address is required.");
      return false;
    } else {
      const validateEmail = isValidEmail(email);
      if (!validateEmail.passed) {
        clearUp();
        setEmailError(validateEmail.error.message);
        return false;
      }

      setEmailError("");
    }

    if (!pass || !rePass) {
      setPassError("Both Password & Repeat Password fields are required.");
      setWaitForAPI(false);
      return false;
    }

    if (pass != rePass) {
      setPassError("Password and Repeat Password do not match.");
      setWaitForAPI(false);
      return false;
    }

    if (!checkPasswordStrength(pass, setPassError)) {
      setWaitForAPI(false);
      return false;
    }

    emailSignUp(email, pass).then((authError) => {
      if (!authError) {
        navigate("/app");
        return;
      }

      setWaitForAPI(false);

      console.log("authError:", authError);
      handleAuthError(authError, setFormError);
    });
  }

  const emailInput = (
    <input
      type="text"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  );

  const passwordInput = (
    <input
      type="password"
      value={pass}
      onChange={(e) => setPass(e.target.value)}
    />
  );

  const repeatPasswordInput = (
    <input
      type="password"
      value={rePass}
      onChange={(e) => setRePass(e.target.value)}
    />
  );

  return (
    <Form onSubmit={handleSubmit} className="first-child">
      {formError && (
        <FormMessage type="error">
          <span>{formError}</span>
        </FormMessage>
      )}
      {passError && (
        <p>
          Passwords must be:
          <ul>
            <li>
              At least <b>6</b> characters long.
            </li>
            <li>
              Not longer than <b>32</b> characters.
            </li>
            <li>
              Contain at least one lowercase <b>[a-z]</b> character.
            </li>
            <li>
              Contain at least one uppercase <b>[A-Z]</b> character
            </li>
            <li>
              Contain at least one <b>[0-9]</b> digit.
            </li>
          </ul>
        </p>
      )}
      <FormField label="Email" input={emailInput} error={emailError} />
      <FormField label="Password" input={passwordInput} error={passError} />
      <FormField label="Repeat Password" input={repeatPasswordInput} />
      <FormButtons>
        <Button
          label={waitForAPI ? "Checking..." : "Sign up"}
          type="submit"
          role="primary"
          disabled={waitForAPI}
        />
        <Button label="Sign in" onClick={() => navigate("/sign-in")} />
      </FormButtons>
    </Form>
  );
}

export function SignUpForm() {
  return (
    <>
      <EmailSignUpForm />
      <AuthProviderButtons />
    </>
  );
}

function SignUpPage({ sideSection }) {
  return (
    <WebsiteSectionWrapper className="cols-2 align-top">
      <div className="col col-sm-100 col-lg-40 col-xl-40 pt-1 xl-pb-2">
        <h1>Sign-up</h1>
        <SignUpForm />
      </div>
      {sideSection ? (
        sideSection
      ) : (
        <div className="col col-sm-100 col-lg-60 col-xl-60 pt-1">
          <DidYouKnow />
        </div>
      )}
    </WebsiteSectionWrapper>
  );
}

export default SignUpPage;
