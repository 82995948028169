import React from "react";
import { Link } from "react-router-dom";
import Button from "../form/Button";

function WebsiteHeaderNav({ mobile, closeMenu }) {
  return (
    <div
      className={`website-header-nav ${mobile && "website-header-nav__mobile"}`}
    >
      <ul>
        {/* <li>
        <Link to="/">Home</Link>
      </li> */}
        <li>
          <Link to="/content/resources/how-does-it-work" onClick={closeMenu}>
            <span className="material-symbols-outlined website-header-nav__mobile__icon-chevron"></span>{" "}
            How does it work?
          </Link>
        </li>
        <li>
          <Link to="/content/services/membership" onClick={closeMenu}>
            <span className="material-symbols-outlined website-header-nav__mobile__icon-chevron"></span>{" "}
            Become a Member
          </Link>
        </li>
        <li>
          <Link to="/content/services/posting-an-advert" onClick={closeMenu}>
            <span className="material-symbols-outlined website-header-nav__mobile__icon-chevron"></span>{" "}
            Post an Advert
          </Link>
        </li>
        <li>
          <Link to="/content/services/pricing" onClick={closeMenu}>
            <span className="material-symbols-outlined website-header-nav__mobile__icon-chevron"></span>{" "}
            Pricing
          </Link>
        </li>
      </ul>
      <Button
        link="/app"
        label="My Account"
        role="primary"
        className="md-display-none"
      />
      <Button
        link="/app"
        label="My Account"
        role="link"
        className="sm-display-none md-mr-0 md-pr-0"
      />
    </div>
  );
}

export default WebsiteHeaderNav;
