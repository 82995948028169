import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useInterval } from "../utils/hooks.js";
import { firebaseAuth } from "../firebase.js";
import { loadSignedInUserData, updateUserProfile } from "../utils/auth.js";
import { UserContext, getDefatulUserContextValue } from "../utils/context.js";
import { timeInSecs } from "../utils/other_utils.js";
import Button from "../components/form/Button.js";

export function requestAutoSave(setUser) {
  setUser((prev) => {
    const update = {
      ...prev,
    };

    update.unsaved = timeInSecs();
    return update;
  });
}

function AppLayout() {
  const [user, setUser] = useState(getDefatulUserContextValue());
  const [loading, setLoading] = useState(!user.profileLoaded);
  const [showAppMenu, setShowAppMenu] = useState(false);
  const navigate = useNavigate();

  function performeAutoSave() {
    if (
      user?.unsaved &&
      user?.profileLoaded &&
      user?.unsaved > user?.profileLoaded + 2
    ) {
      updateUserProfile(user.auth.accessToken, user);

      setUser((prev) => {
        const update = { ...prev };
        update.unsaved = 0;
        update.saving = false;
        return update;
      });
    }
  }

  useInterval(() => {
    performeAutoSave();
  }, 3000);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (authUser) => {
      if (authUser?.accessToken && !user.profileLoaded) {
        await loadSignedInUserData(authUser.accessToken, setUser);

        setUser((prev) => {
          const update = {
            ...prev,
            emailVerified: authUser?.emailVerified,
          };

          if (!update?.contact_details?.name) {
            update.contact_details.name = authUser?.displayName;
          }

          return update;
        });

        setLoading(false);
      }
    });
  }, []);

  onAuthStateChanged(firebaseAuth, (authUser) => {
    if (!authUser || !authUser.uid || !authUser.accessToken) {
      setUser(getDefatulUserContextValue());
      navigate("/sign-in");
    }
  });

  function toggleAppMenu() {
    setShowAppMenu(!showAppMenu);
  }

  function getAppMenu(largeScreen) {
    return (
      <ul className={`app-menu ${largeScreen && "app-menu--large-screen"}`}>
        <li>
          <Link to="/app" onClick={() => setShowAppMenu(false)}>
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/app/settings" onClick={() => setShowAppMenu(false)}>
            Settings
          </Link>
        </li>
        <li>
          <Link to="/sign-out" onClick={() => setShowAppMenu(false)}>
            Sign out
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <div className="app-layout">
      <UserContext.Provider value={{ user, setUser }}>
        {false && (
          <div className="app-save-bar">
            <Button label="Save Changes" />
          </div>
        )}
        {user?.unsaved > 0 && <span className="auto-save">saving...</span>}

        <div className="app-header">
          <nav>
            <Link to="/">
              <div className="text-logo">
                <span className="text-logo__f">Freelancers</span>
                <span className="text-logo__d">Directory</span>
              </div>
              <span className="text-logo__beta">beta</span>
            </Link>
            {!showAppMenu ? (
              <span
                onClick={toggleAppMenu}
                className="material-symbols-outlined icon-app-menu"
              ></span>
            ) : (
              <span
                onClick={toggleAppMenu}
                className="material-symbols-outlined icon-app-menu--close"
              ></span>
            )}
            {getAppMenu(true)}
          </nav>
          {showAppMenu && getAppMenu(false)}
        </div>
        <div className="app-page-content">
          {user.profileLoaded && !user.emailVerified && (
            <div className="error-container">
              Hey there! Your login email is not verified yet! Check your inbox
              for the verification link!
            </div>
          )}
          {loading ? <h4>Loading...</h4> : <Outlet />}
        </div>
        <div className="app-footer"></div>
      </UserContext.Provider>
    </div>
  );
}

export default AppLayout;
