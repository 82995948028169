import React from "react";
import { Link } from "react-router-dom";
import ContentLayout from "../layout/ContentLayout";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";

function ThankYouPage() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page">
        <h1>Thank You for Getting in Touch!</h1>
        <p>
          We’ve received your message and our team will get back to you as soon
          as possible—usually within a few hours.
        </p>
        <p>
          You can always reach us directly at <b>team@freelancers.directory</b>.
        </p>
        <p>Thanks again for reaching out!</p>
      </WebsiteSectionWrapper>
    </ContentLayout>
  );
}

export default ThankYouPage;
