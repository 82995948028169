import React from "react";
import WebsiteSectionWrapper from "../layout/WebsiteSectionWrapper";
import { Link } from "react-router-dom";

function RelatedLinks({ links }) {
  if (!links || links.length == 0) {
    return null;
  }

  return (
    <WebsiteSectionWrapper className="related-links xl-mt-2">
      <h3>See also:</h3>
      <ul>
        {links.map((el) => (
          <li key={el.text}>
            <Link to={el.link}>{el.text}</Link>
          </li>
        ))}
      </ul>
    </WebsiteSectionWrapper>
  );
}

export default RelatedLinks;
