import React from "react";
import { Link } from "react-router-dom";

function MembershipSettingsPage() {
  return (
    <div className="settings-section membership-settings-section">
      <div className="settings-section__header">Membership</div>
      <div className="settings-section__content">
        <h4>You're in Beta!</h4>
        <p>
          Right now, all users are part of our beta phase, so everything is free
          of charge while we finalise our application.
        </p>
        <p>
          Once we're ready, paid plans will be introduced, but don't worry—basic
          access will always remain free. For more details, check out our{" "}
          <Link to="/content/services/pricing">Pricing Page</Link>.
        </p>
      </div>
    </div>
  );
}

export default MembershipSettingsPage;
