import React from "react";
import ContentLayout from "../../layout/ContentLayout";
import WebsiteSectionWrapper from "../../layout/WebsiteSectionWrapper";
import FreelancersNetworking from "../../assets/images/FreelancersNetworking.svg";

function WhyFreelancersDirectoryContent() {
  return (
    <ContentLayout>
      <WebsiteSectionWrapper className="home-page cols-2">
        <div className="col col-sm-100 col-lg-60 col-xl-40">
          <img src={FreelancersNetworking} />
        </div>
        <div className="col col-sm-100 col-lg-40 col-xl-60">
          <h1>Why US?</h1>
          <h4>Your go-to platform for seamless connections.</h4>
          <br />
          <p>
            We understand the challenges freelancers face and the difficulties
            businesses encounter when finding the right talent. That’s why we’ve
            built a platform that makes the process simple and stress-free.
          </p>
          <p>
            Whether you’re looking to land your next gig or hire a freelancer,
            we’ve made it easy to find the perfect match. Plus, with continuous
            updates and new features, we're committed to improving your
            experience every day.
          </p>
        </div>
      </WebsiteSectionWrapper>

      <ul className="no-style">
        <li>
          <h3>We Get You</h3>
          <p>
            As freelancers ourselves, we know the challenges and stress of
            constantly searching for work or finding the right collaborators.
            That’s why we built our platform—to make life easier for freelancers
            and the clients who want to hire them.
          </p>
        </li>
        <li>
          <h3>Total Privacy</h3>
          <p>
            Your privacy is our priority. All of your information stays with
            you—none of your details are ever shared with advertisers or third
            parties. Advertisers don’t need access to your personal info.
            Instead, they filter through our directory by setting specific
            criteria, and we show them only the number of freelancers that
            match. From there, they can go ahead and send their ad to those
            selected freelancers, without ever having to access their details.
          </p>
        </li>
        <li>
          <h3>Set & Forget</h3>
          <p>
            Once you sign up and complete your profile, you’re all set. You
            won’t need to sign in constantly to check for opportunities or
            communicate with the advertisers. We’ll send you an email whenever a
            relevant ad matches your criteria. The email will include all the
            details you need to get in touch with the advertiser directly e.g.
            via phone or email, thus, saving you time and effort.
          </p>
        </li>
        <li>
          <h3>Smart Search for Advertisers</h3>
          <p>
            Advertisers can quickly find the right freelancers by setting
            criteria like location and experience. We’ll show them how many
            freelancers fit the bill, and they can adjust the search until
            they’re happy. Once that’s done, they just craft the ad and hit
            send!
          </p>
        </li>
        <li>
          <h3>We Keep It Focused</h3>
          <p>
            We’re not trying to be everything to everyone. Instead, we
            specialise in a few freelance niches, making sure we’re the best at
            what we do for the people who trusted us. That’s why we’re currently
            limiting sign-ups to freelancers in mostly Creative industry. While
            we plan to expand into other fields in the future, our priority for
            now is to be the best for a smaller group rather than just okay at
            covering multiple areas.
          </p>
        </li>
      </ul>
    </ContentLayout>
  );
}

export default WhyFreelancersDirectoryContent;
