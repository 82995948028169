import React from "react";

const cssBaseClass = "icon-list";

export function IconListItem({ icon, children, className, alignTextTop }) {
  return (
    <div className={`icon-list__item ${className}`}>
      <div className={`icon-list__item__icon`}>{icon}</div>
      <div
        className={`icon-list__item__text ${
          alignTextTop && "justify-content-start"
        }`}
      >
        {children}
      </div>
    </div>
  );
}

function IconList({ children, size, className, ...rest }) {
  if (!size) {
    size = "large";
  }

  return (
    <div className={`icon-list icon-list--${size} ${className}`} {...rest}>
      {children}
    </div>
  );
}

export default IconList;
